/* @flow */

import * as React from 'react'

import { getQueryVariable } from '../utils'
import { ErrorTemplate } from '../templates'

type Props = {
  location: {
    href: string,
  },
}

export default function Error(props: Props) {
  const message =
    getQueryVariable(props.location, 'message') ||
    'Retenter votre chance ou retourner sur l’accueil du site en cliquant sur le bouton ci-dessous'
  return (
    <ErrorTemplate
      url={props.location.href}
      code={500}
      name="Wino"
      title="Ooops ! Une erreur est survenue"
      description={message}
    />
  )
}
